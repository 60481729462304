import React, { Suspense, memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'primereact/image';
import { ProgressSpinner } from 'primereact/progressspinner';
import dayjs from 'dayjs';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import profilePhoto from "../assets/icons/profile.svg";
import LockIcon from "../assets/icons/lock-icon.svg";
import PagoIcon from "../assets/icons/pago-icon.svg";
import { ReactComponent as EyeHideIcon } from "../assets/icons/eye-hide-icon.svg";
import { ReactComponent as EyeViewIcon } from "../assets/icons/eye-view-icon.svg";
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import { ButtonPrimary } from '../components/Button';
import Loading from '../components/Loading';
import TelefonoCodes from '../components/TelefonoCodes';
import GlobalContext from '../contexts/GlobalContext';
import { useCustomState } from '../hooks/useCustomState';
import { registerUser } from '../redux/reducers/authSlice';
import intersectionObserver from '../utils/intersectionObserver';
import { setSubscription } from '../redux/reducers/subscriptionSlice';
import { getApiKeys } from '../api/client/config';
import { getCupones } from '../api/client/cupones';
import { comprar_suscripcion, crear_suscripcion_paypal, getClienteSecreto, getPruebaGratis } from '../api/client/pagos';
// import { getSubscription } from '../api/client/mi_suscripcion';
import { getProgramas } from '../api/client/programas';
import { optionsToast } from '../config/toast';
import { apiKeys, WEB_BASE_URL } from '../config/app';
import parseErrorMessage from '../utils/parseErrorMessage';
import calculateDiscount from '../utils/calculateDiscount';
import { dayjs_custom } from '../utils/dayjs_custom';
import getHeaderImageProgram from '../utils/getHeaderImageProgram';
import { isEmail, isEmpty } from '../utils';

const Carrito = ({ apiKeysRemote }) => {
  const elementsRef = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { setModal } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const discount_code = searchParams.get("discount_code");
  const isRegister = searchParams.get("is_register");
  const selectProgram = searchParams.get("select_program");
  const [programa_seleccionado, plan_seleccionado] = params?.suscripcion?.split('-') || ['', ''];

  const [state, setState] = useCustomState({
    sending: {
      cupon: false,
      register: false,
      pay: false,
      isTrial: true,
    },
    tiene_prueba_gratis: null,
    changeSubscription: false,
    programas: [],
    cicloSeleccionado: null,
    programaSeleccionado: null,
    registro_cuenta: {
      nombre: "",
      correo: "",
      phone_code: "",
      telefono: "",
      correo_confirmacion: "",
      contrasenia: "",
      visiblePassword: false,
      terminos_condiciones: false,
    },
    metodo_pago: {
      cupon: discount_code || "",
      codigo_postal: "",
    },
    cupon_detalles: null,
    setupIntentId: null,
    optionsStripe: {
      clientSecret: null,
      paymentMethodCreation: "manual",
    }
  });

  const { isAuthenticated, user } = useSelector(state => state.app.auth);
  const { data: suscripcion_seleccionada } = useSelector(state => state.app.subscription);
  const headerImage = getHeaderImageProgram(suscripcion_seleccionada?.code);
  const descuento_precio = state.cupon_detalles?.id ? calculateDiscount(suscripcion_seleccionada.precio, state.cupon_detalles?.discount, state.cupon_detalles?.type) : null;

  useEffect(() => {
    if (isEmpty(programa_seleccionado) || isEmpty(plan_seleccionado)) {
      const suscripcion_seleccionada_nombre = !isEmpty(suscripcion_seleccionada?.nombre) ? (suscripcion_seleccionada?.nombre?.replace("Fitmom", "")?.trim() || "").toLowerCase() : null;
      if (suscripcion_seleccionada_nombre) navigate(`/carrito/${suscripcion_seleccionada_nombre}-${suscripcion_seleccionada?.plan}`);
    }
    // eslint-disable-next-line
  }, [programa_seleccionado, plan_seleccionado, suscripcion_seleccionada?.nombre, suscripcion_seleccionada?.plan]);

  useEffect(() => {
    if (isEmpty(suscripcion_seleccionada?.id)) setState({ changeSubscription: isRegister });
    // eslint-disable-next-line
  }, [suscripcion_seleccionada?.id, isRegister]);

  useEffect(() => {
    if (selectProgram === "true" || selectProgram === "1") setState({ changeSubscription: selectProgram });
    // eslint-disable-next-line
  }, [selectProgram]);

  useEffect(() => {
    onSearchCoupon(discount_code);
    // eslint-disable-next-line
  }, [discount_code, suscripcion_seleccionada?.id]);

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    if (!isEmpty(user?.userID)) {
      onFindTrial(user?.userID);
    }

    getProgramas().then(response => setState({ programas: response?.data || [] }));

    // eslint-disable-next-line
  }, [user?.userID]);

  const stripePromise = useMemo(() => {
    if (!isEmpty(user?.userID)) {
      const _stripePromise = getClienteSecreto(user?.userID).then(response => {
        setState({ clientSecret: response?.data?.client_secret }, 'optionsStripe');
        setState({ setupIntentId: response?.data?.id });
        return loadStripe(apiKeysRemote.stripe);
      });

      return _stripePromise;
    } else {
      return null;
    }
    // eslint-disable-next-line
  }, [user?.userID, apiKeysRemote.stripe]);

  const { haveSubscriptionActive } = useMemo(() => {
    const start_time = ["expired", "paused", "rejected", "cancelled"].includes(user?.subscription?.status) ? (user?.subscription?.subscriptionCancelDate || user?.subscription?.nextBillingDate) : user?.subscription?.nextBillingDate;
    const haveSubscriptionActive = dayjs_custom(new Date(), { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' }).isBefore(dayjs_custom(start_time, { tz: null, noTz: true, fromFormat: 'YYYY-MM-DD' }), 'day') === true;

    return { haveSubscriptionActive };
  }, [user?.subscription?.status, user?.subscription?.nextBillingDate, user?.subscription?.subscriptionCancelDate]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onFindTrial = useCallback(async (userID) => {
    try {
      setState({ isTrial: true }, 'sending');
      const get_trial = await getPruebaGratis(userID);

      setState({ tiene_prueba_gratis: get_trial?.isTrial || false });
      setState({ isTrial: false }, 'sending');
    } catch (error) {
      setState({ tiene_prueba_gratis: false });
      setState({ isTrial: false }, 'sending');
    }
  }, [setState]);

  const onSearchCoupon = useCallback(async (_discount_code) => {
    if (state.cupon_detalles?.code !== _discount_code && !isEmpty(_discount_code)) {
      // if (state.tiene_prueba_gratis === true) return message(null, "No se permite usar cupones cuando tienes periodo de prueba");

      try {
        setState({ cupon: true }, 'sending');
        setState({ cupon: _discount_code }, 'metodo_pago');

        const cupon = await getCupones({ code: _discount_code, programID: suscripcion_seleccionada?.id, planType: suscripcion_seleccionada?.plan });
        if (cupon?.status === true) {
          const _cupon = cupon?.data?.[0];
          setState({ cupon: false }, 'sending');
          if (_cupon?.limit_users > 0 && _cupon?.cupones_restantes <= 0) {
            setState({ cupon_detalles: null });
            return message(null, "El cupón ya no está disponible o se ha agotado");
          } else if (_cupon?.isValid !== true) {
            setState({ cupon_detalles: null });
            return message(null, "El cupón ha expirado o no es válido");
          }

          setState({ cupon_detalles: _cupon });
          message(null, "El código de cupón se ha aplicado correctamente");
        } else {
          setState({ cupon_detalles: null });
          setState({ cupon: false }, 'sending');
          message('Cupón incorrecto', 'Lo sentimos, el cupón no existe o es incorrecto.');
        }
      } catch (error) {
        setState({ cupon_detalles: null });
        setState({ cupon: false }, 'sending');
        message('Cupón inválido', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error con el cupón de descuento. Por favor, inténtalo de nuevo más tarde.');
      }
    } else if (isEmpty(_discount_code)) {
      setState({ cupon_detalles: null });
    }
  }, [state.cupon_detalles?.code, suscripcion_seleccionada?.id, suscripcion_seleccionada?.plan, setState]);

  const crearCuenta = useCallback((event, tokenCaptcha) => {
    event?.preventDefault();

    if (isEmpty(state.registro_cuenta.nombre)) return message(null, "El nombre es requerido");
    if (isEmpty(state.registro_cuenta.correo)) return message(null, "La dirección de correo electrónico es requerida");
    if (!isEmail(state.registro_cuenta.correo)) return message(null, "La dirección de correo electrónico no es válido");
    if (state.registro_cuenta.correo !== state.registro_cuenta.correo_confirmacion) return message(null, "Los correos electrónicos no coinciden");
    if (isEmpty(state.registro_cuenta.contrasenia)) return message(null, "La contraseña es requerida");
    // if (state.registro_cuenta.contrasenia !== state.registro_cuenta.contrasenia_confirmacion) return message(null, "Las contraseñas no coinciden");
    if (state.registro_cuenta.terminos_condiciones !== true) return message(null, "Selecciona los términos y condiciones para continuar");
    if (isEmpty(tokenCaptcha)) return message(null, "Selecciona la verificación de ReCaptcha para continuar");

    setState({ register: true }, 'sending');
    dispatch(
      registerUser({
        email: state.registro_cuenta?.correo,
        password: state.registro_cuenta?.contrasenia,
        firstName: state.registro_cuenta?.nombre,
        lastName: state.registro_cuenta?.apellido_paterno,
        lastName2: state.registro_cuenta?.apellido_materno,
        phone_code: state.registro_cuenta?.phone_code,
        phone: state.registro_cuenta?.telefono,
        // zipCode: state.registro_cuenta?.codigo_postal,
        // city: state.registro_cuenta?.ciudad,
        device: "web",
        token: tokenCaptcha
      })
    )
      .then((response) => {
        setState({ register: false }, 'sending');
        if (response?.status === true) {
          // if (navigate) navigate('/mi-cuenta', { replace: true });
        } else {
          message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al registrarse. Por favor, inténtalo de nuevo más tarde.');
        }
      });

    // eslint-disable-next-line 
  }, [state.registro_cuenta, setState, dispatch, navigate]);

  const updateSubscriptionSelected = useCallback((subscription, plan) => {
    const cycleType = state.cicloSeleccionado && (state.cicloSeleccionado?.indexOf("mensual") !== -1 ? "mensual" : "semestral");
    const suscripcion_seleccionada_nombre = (subscription?.name?.replace("Fitmom", "")?.trim()).toLowerCase();

    if (isEmpty(cycleType)) {
      return message(null, "Selecciona el tipo de ciclo para continuar");
    }

    dispatch(setSubscription({
      data: {
        id: subscription?.id,
        tipo_programa: "Programa de entrenamiento",
        code: subscription?.code,
        nombre: subscription?.name,
        plan: plan,
        precio: plan === "mensual" ? subscription?.cost_month : subscription?.cost_biannual,
      }
    }));
    setState({ changeSubscription: false });
    navigate(`/carrito/${suscripcion_seleccionada_nombre}-${plan}`);
  }, [state.cicloSeleccionado, setState, dispatch, navigate]);

  return (
    <>
      <SEO
        title="Carrito"
        description=""
      />
      {!isEmpty(suscripcion_seleccionada?.id) ? (
        <div className={`bg-Crayola/40 bg-gradient-to-tl from-primary to-secondary bg-cover bg-center h-60 lg:h-128`} style={{ backgroundImage: `url(${headerImage})` }}>
          <HeaderNav />
        </div>
      ) : (
        <HeaderNav theme={"white"} />
      )}

      {(!isEmpty(suscripcion_seleccionada?.id) || state.changeSubscription) ? (
        <>
          <div className='bg-Magnolia lg:-mt-8'>
            <div className="xl:container mx-auto px-6">
              <div className={`flex justify-center relative z-10 ${!isEmpty(suscripcion_seleccionada?.id) ? "-top-[4rem] lg:-top-24" : "top-0 mt-[10rem] mb-[4rem]"}`}>
                {state.changeSubscription ? (
                  <div>
                    <div className='inline-flex flex-col md:flex-row flex-wrap justify-center gap-4 animate__animated animate__fadeIn'>
                      {Array.isArray(state?.programas) && state.programas?.map((item, index) => (
                        <div key={`programa-key-${item?.id || index}`} className='relative bg-white shadow-lg shadow-DarkJungleGreen/30 rounded-xl text-center animate__animated animate__fadeIn'>
                          <div className='bg-Orchid py-3 px-6 mb-6 rounded-t-xl'>
                            <p className='font-golos-semibold text-lg md:text-xl text-center text-white mb-0'>{item?.name}</p>
                          </div>
                          <div className='pb-8 px-7'>
                            <div className='flex justify-center items-center px-5 mt-4'>
                              <div
                                className={`${state.cicloSeleccionado === `${item?.code}-mensual` ? "bg-Orchid border border-Orchid text-white font-golos-medium" : "bg-white border border-gray-200 text-Crayola"} py-1.5 px-2 pr-3 rounded-l-md c-pointer`}
                                onClick={() => setState({ programaSeleccionado: item, cicloSeleccionado: `${item?.code}-mensual` })}
                              >
                                <p className='text-sm'>Mensual</p>
                              </div>
                              <div
                                className={`${state.cicloSeleccionado === `${item?.code}-semestral` ? "bg-Orchid border border-Orchid text-white font-golos-medium" : "bg-white border border-gray-200 text-Crayola"} py-1.5 px-2 -ml-1 rounded-r-md c-pointer`}
                                onClick={() => setState({ programaSeleccionado: item, cicloSeleccionado: `${item?.code}-semestral` })}
                              >
                                <p className='text-sm'>Semestral</p>
                              </div>
                            </div>
                            <p className='font-golos-semibold text-xl md:text-2xl text-Crayola mt-4'>
                              <span>${item?.code === state?.programaSeleccionado?.code ? parseFloat(state.cicloSeleccionado === `${item?.code}-mensual` ? item?.cost_month : item?.cost_biannual)?.toFixed(2) : "0.00"}</span>
                              <span className='font-golos-medium text-lg lg:text-xl'>USD</span>
                            </p>
                            {item?.code === state?.programaSeleccionado?.code && (
                              <div className='mt-4'>
                                <ButtonPrimary
                                  text={(item?.code === suscripcion_seleccionada?.code && `${item?.code}-${suscripcion_seleccionada?.plan}` === state.cicloSeleccionado) ? "Seleccionado" : "Seleccionar"}
                                  withIcon={(item?.code === suscripcion_seleccionada?.code && `${item?.code}-${suscripcion_seleccionada?.plan}` === state.cicloSeleccionado) ? false : true}
                                  disabled={((item?.code === suscripcion_seleccionada?.code && `${item?.code}-${suscripcion_seleccionada?.plan}` === state.cicloSeleccionado)) ? true : false}
                                  classNameContainer="flex justify-center"
                                  onClick={() => updateSubscriptionSelected(item, state.cicloSeleccionado?.indexOf("mensual") !== -1 ? "mensual" : "semestral")}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    {!isEmpty(suscripcion_seleccionada?.id) && (
                      <div className='flex justify-center mt-16 -mb-[2rem]'>
                        <ButtonPrimary
                          text={(isRegister || selectProgram) ? "Regresar" : "Cancelar"}
                          classNameButton={"py-3 px-8"}
                          type="button"
                          onClick={() => setState({ changeSubscription: false })}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='inline-flex flex-col md:flex-row justify-center rounded-xl shadow-md md:shadow-xl shadow-DeepKoamaru/20 animate__animated animate__fadeIn'>
                    <div className='flex flex-col items-center justify-center'>
                      <div className='flex flex-col justify-center flex-1 bg-Crayola px-6 lg:px-14 py-5 rounded-t-xl md:rounded-tr-none md:rounded-l-xl text-center lg:text-left w-full'>
                        <p className='font-golos text-white mb-4'>{suscripcion_seleccionada.tipo_programa}:</p>
                        <div>
                          <div className='md:max-w-[160px] mx-auto lg:mx-none mb-4'>
                            <p className='font-golos-semibold text-xl md:text-2xl lg:text-3xl text-white uppercase !leading-7'>{suscripcion_seleccionada.nombre}</p>
                            <p className='font-golos-semibold text-xl md:text-2xl lg:text-3xl text-white uppercase !leading-7'>{suscripcion_seleccionada.plan}</p>
                          </div>
                          <p className='font-golos-semibold text-DeepKoamaru underline select-none c-pointer active:scale-95 transition-all' onClick={() => setState({ changeSubscription: true })}>Cambiar</p>
                        </div>
                      </div>
                    </div>
                    <div className='bg-white px-6 lg:px-14 py-6 rounded-b-xl md:rounded-bl-none md:rounded-r-xl'>
                      <div className='grid grid-cols-1 xs:grid-cols-2 gap-4 items-center mb-5'>
                        {(isAuthenticated && state.sending?.isTrial) ? (
                          <Loading onlyIcon />
                        ) : (
                          <div className='bg-LavenderBlush border-Orchid border rounded-xl p-4 max-w-[190px] mx-auto md:mx-none'>
                            {(state.tiene_prueba_gratis === true && state.cupon_detalles?.id) ? (
                              <p className='font-golos-semibold text-Orchid text-center text-xl lg:text-xl !leading-2'>No hay periodo de prueba en precio promocional</p>
                            ) : (
                              <>
                                {(state.tiene_prueba_gratis === true || !isAuthenticated) ? (
                                  <>
                                    <p className='text-DeepKoamaru text-center mb-2'>Incluye:</p>
                                    <p className='font-golos-semibold text-Orchid text-center text-xl lg:text-2xl !leading-6'>Prueba de</p>
                                    <p className='font-golos-semibold text-Orchid text-center text-xl lg:text-2xl !leading-6 mb-2'>7 días gratis</p>
                                  </>
                                ) : ((haveSubscriptionActive) ? (
                                  <p className='font-golos-semibold text-Orchid text-center text-xl'>Todavía tienes una suscripción activa</p>
                                ) : (
                                  <p className='font-golos-semibold text-Orchid text-center text-xl'>El periodo de prueba ya ha sido utilizado anteriormente</p>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                        <div>
                          <p className='text-sm text-center mb-2'>{state.tiene_prueba_gratis === true ? "al terminar periodo:" : "precio del programa:"}</p>
                          {(state.cupon_detalles?.id || haveSubscriptionActive) && (
                            <p className='font-golos-semibold text-neutral-300 text-center mb-2'>
                              <span className='text-xl sm:text-2xl lg:text-4xl line-through'>${parseFloat(suscripcion_seleccionada.precio || 0)?.toFixed(2)}</span>
                              <span className='font-golos-medium text-lg sm:text-xl lg:text-3xl line-through'>USD</span>
                            </p>
                          )}
                          <p className='font-golos-semibold text-Crayola text-center mb-2'>
                            <span className='text-2xl sm:text-3xl lg:text-5xl'>${(state.cupon_detalles?.id || haveSubscriptionActive) ? parseFloat(state.cupon_detalles?.id ? descuento_precio : 0)?.toFixed(2) : parseFloat(suscripcion_seleccionada.precio)?.toFixed(2)}</span>
                            <span className='font-golos-medium text-xl sm:text-2xl lg:text-4xl'>USD</span>
                          </p>
                          <p className='text-DarkJungleGreen/90 text-xs text-center mb-2'>{suscripcion_seleccionada?.plan === "mensual" ? "El cobro se realizará mes a mes." : "El cobro se realizará cada 6 meses."}</p>
                        </div>
                      </div>
                      <p className='text-DarkJungleGreen text-sm text-center'>*Puedes cancelar cuando quieras.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!state.changeSubscription && (
            <>
              <div className='bg-Magnolia pb-20'>
                <div className="xl:container mx-auto px-6">
                  {!(isAuthenticated) ? (
                    <Suspense fallback={<Loading />}>
                      <GoogleReCaptchaProvider
                        reCaptchaKey={apiKeys.captcha}
                        language={"es"}
                        scriptProps={{
                          defer: true, // optional, default to false
                          appendTo: 'head', // optional, default to "head", can be "head" or "body",
                          nonce: undefined // optional, default undefined
                        }}
                        container={{ // optional to render inside custom element
                          element: "grecaptcha",
                          parameters: {
                            badge: 'inline', // optional, default undefined
                            theme: 'light', // optional, default undefined
                          }
                        }}
                      >
                        <CrearCuentaDesign
                          crearCuenta={crearCuenta}
                          state={state}
                          setState={setState}
                          message={message}
                          setModal={setModal}
                          navigate={navigate}
                        />
                      </GoogleReCaptchaProvider>
                    </Suspense>
                  ) : (
                    <>
                      {(isEmpty(state?.optionsStripe?.clientSecret) || !stripePromise) ? (
                        <Loading />
                      ) : (
                        <Suspense fallback={<Loading />}>
                          <Elements stripe={stripePromise} options={state.optionsStripe}>
                            <MetodoPagoDesign
                              haveSubscriptionActive={haveSubscriptionActive}
                              onSearchCoupon={onSearchCoupon}
                              user={user}
                              state={state}
                              setState={setState}
                              navigate={navigate}
                              optionsStripe={state.optionsStripe}
                            />
                          </Elements>
                        </Suspense>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className='bg-white'>
                <div className='xl:container mx-auto px-6 py-8 flex flex-col md:flex-row items-center gap-6'>
                  <div>
                    <Image
                      src={require("../assets/images/payment-icons.png")}
                      alt="Logo de pagos"
                      imageStyle={{ maxWidth: 220, margin: "auto" }}
                    />
                  </div>
                  <div className='flex-1'>
                    <p className='text-center md:text-left'>Puedes adquirir el plan desde cualquier parte del mundo, la conversión se hace en tu moneda local de acuerdo al tipo de cambio del día</p>
                  </div>
                  <div className='flex items-center space-x-4 mx-auto md:mr-0 md:max-w-[200px]'>
                    <Image
                      src={LockIcon}
                      alt="Icono de seguridad"
                      imageClassName='w-4 min-w-[1rem]'
                    />
                    <p className='font-golos-semibold text-Crayola'>Pago y protección de datos 100% seguro</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="bg-Magnolia flex flex-1 items-center justify-center px-4 py-4 mt-8">
          <div className='inline-flex flex-col items-center justify-center bg-white rounded-xl py-10 px-10 mt-12'>
            <h1 className='font-golos-semibold text-lg text-Crayola text-center'>No tienes suscripciones seleccionadas.</h1>
            <h1 className='font-golos-semibold text-lg text-Crayola text-center'>Necesitas seleccionar una para continuar.</h1>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

const CrearCuentaDesign = memo(({ crearCuenta, state, setState, message, navigate, setModal }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSend = useCallback(async (event) => {
    event.preventDefault();

    if (!executeRecaptcha) {
      message('La protección de reCAPTCHA no está disponible.');
      return;
    }

    try {
      const token = await executeRecaptcha('submit');
      return crearCuenta(event, token);
    } catch (error) {
      message("Ocurrio un error con la verificación de reCAPTCHA");
    }
  }, [crearCuenta, executeRecaptcha, message]);

  return (
    <>
      <div className='grid md:grid-cols-12 gap-10'>
        <div className='md:col-span-10 flex flex-1 items-center justify-center md:justify-start gap-6'>
          <div>
            <Image
              src={profilePhoto}
              imageClassName="w-11 min-w-[2.75rem] select-none pointer-events-none"
            />
          </div>
          <div className='col-span-1'>
            <h1 className='font-golos-semibold text-Crayola text-left text-3xl mb-1'>Completa la información de cuenta</h1>
            <p>¿Ya tienes una cuenta? <span className='text-DarkJungleGreen font-golos-semibold underline c-pointer' onClick={() => setModal("login", navigate)}>Ingresa aquí</span></p>
          </div>
        </div>
        <div className='col-span-1 md:col-span-2'>
          <p className='text-center md:text-left'>Paso 1 de 2</p>
        </div>
      </div>
      <div className='mt-14'>
        <form onSubmit={handleSend}>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <div className='space-y-4'>
              <input
                name="nombre"
                type="text"
                required
                placeholder='Nombre'
                className="border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-lg text-sm text-gray-900 w-full transition-all"
                value={state.registro_cuenta.nombre}
                onChange={(event) => setState({ nombre: event.target.value }, "registro_cuenta")}
              />
              <input
                name="apellido_paterno"
                type="text"
                placeholder='Apellido paterno'
                className="border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-lg text-sm text-gray-900 w-full transition-all"
                value={state.registro_cuenta.apellido_paterno}
                onChange={(event) => setState({ apellido_paterno: event.target.value }, "registro_cuenta")}
              />
              <input
                name="apellido_materno"
                type="text"
                placeholder='Apellido materno'
                className="border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-lg text-sm text-gray-900 w-full transition-all"
                value={state.registro_cuenta.apellido_materno}
                onChange={(event) => setState({ apellido_materno: event.target.value }, "registro_cuenta")}
              />
              <TelefonoCodes
                theme={"white"}
                selectedCode={state.registro_cuenta?.phone_code}
                onChangeCode={(event) => setState({ phone_code: event.value }, "registro_cuenta")}
                value={state.registro_cuenta.telefono}
                onChange={(event) => setState({ telefono: event.target.value }, "registro_cuenta")}
              />
              {/* <input
                name="codigo_postal"
                type="text"
                placeholder='Código postal'
                className="border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-lg text-sm text-gray-900 w-full transition-all"
                value={state.registro_cuenta.codigo_postal}
                onChange={(event) => setState({ codigo_postal: event.target.value }, "registro_cuenta")}
              />
              <input
                name="ciudad"
                type="text"
                placeholder='Ciudad'
                className="border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-lg text-sm text-gray-900 w-full transition-all"
                value={state.registro_cuenta.ciudad}
                onChange={(event) => setState({ ciudad: event.target.value }, "registro_cuenta")}
              /> */}
            </div>
            <div className='space-y-4'>
              <input
                name="correo"
                type="email"
                required
                placeholder='Correo electrónico'
                className="border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-lg text-sm text-gray-900 w-full transition-all"
                value={state.registro_cuenta.correo}
                onChange={(event) => setState({ correo: event.target.value }, "registro_cuenta")}
              />
              <input
                name="correo_confirmacion"
                type="email"
                required
                placeholder='Confirma correo electrónico'
                className="border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-lg text-sm text-gray-900 w-full transition-all"
                value={state.registro_cuenta.correo_confirmacion}
                onChange={(event) => setState({ correo_confirmacion: event.target.value }, "registro_cuenta")}
              />
              <div className='relative flex items-center'>
                <input
                  autoComplete='new-password'
                  name="contrasenia"
                  type={state.visiblePassword ? "text" : "password"}
                  required
                  placeholder='Contraseña'
                  className="pr-10 border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-lg text-sm text-gray-900 w-full transition-all"
                  value={state.registro_cuenta.contrasenia}
                  onChange={(event) => setState({ contrasenia: event.target.value }, "registro_cuenta")}
                />
                <span className='absolute right-0 p-3 c-pointer z-10' onClick={() => setState({ visiblePassword: !state.visiblePassword })}>
                  {state.visiblePassword ? <EyeHideIcon height={16} width={16} /> : <EyeViewIcon height={16} width={16} />}
                </span>
              </div>
              <p className='text-DarkJungleGreen/60 text-center sm:text-left'><span className='font-golos-semibold'>Sugerencia:</span> La contraseña debe ser de al menos ocho caracteres. <br />Para hacerla más fuerte usa mayúsculas y minúsculas, números y símbolos como los siguientes: <span className='font-golos-medium whitespace-nowrap'>(! " ? $ % ^)</span>.</p>
            </div>
          </div>
          <div className='grid md:grid-cols-2 items-center mt-8 gap-6 break-word'>
            <div className='flex justify-center'>
              <label className='inline-flex items-center gap-4'>
                <input
                  required
                  type="checkbox"
                  className="!border-0 !outline-none !shadow-none !ring-0 !ring-offset-0 p-2.5 !rounded-md text-Crayola transition-all"
                  onChange={(event) => setState({ terminos_condiciones: event.target.checked }, "registro_cuenta")}
                />
                <span>Acepto los <Link to="/terminos-condiciones" className='font-golos-medium underline' target="_blank">Términos y condiciones</Link>, así como la  <Link to="/politica-privacidad" className='font-golos-medium underline' target="_blank">Política de privacidad</Link> de Jimefitmom.</span>
              </label>
            </div>
            <div className='flex flex-col sm:flex-row items-center justify-center md:justify-end gap-6'>
              <div id="grecaptcha" />
              <ButtonPrimary
                withIcon
                text="Crear cuenta y continuar"
                classNameContainer={"flex justify-end"}
                loading={state.sending?.register}
                type="submit"
                onSubmit={handleSend}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  )
});

const MetodoPagoDesign = memo(({ haveSubscriptionActive, onSearchCoupon, user, state, setState, navigate }) => {
  const [searchParams] = useSearchParams();
  const method_payment = searchParams.get("method_payment");
  const isValidMethodPayment = ["card", "paypal"].includes(method_payment);

  const [stateMetodoPago, setStateMetodoPago] = useCustomState({
    tabSelected: (isValidMethodPayment && method_payment) || "card",
  });
  const { data: suscripcion_seleccionada } = useSelector(state => state.app.subscription);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [{ isPending }] = usePayPalScriptReducer();
  const programa_seleccionado = state?.programas?.find(item => parseInt(item?.id) === parseInt(suscripcion_seleccionada?.id));

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  async function handleCheckoutStripe(event) {
    event.preventDefault();
    if (isEmpty(suscripcion_seleccionada?.id) || isEmpty(suscripcion_seleccionada?.plan) || isEmpty(user?.userID)) {
      return message("Información incompleta", "Se encontro información requerida que no se esta enviando");
    }
    if (isEmpty(state.metodo_pago.codigo_postal)) {
      return message("Información incompleta", "El código postal es requerido para continuar");
    }

    dispatch(setSubscription({ isPaid: false }));

    try {
      setState({ pay: true }, 'sending');

      elements.submit();
      const { error: errorCreatePayment, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: user?.fullName || user?.firstName,
            address: {
              country: "MX",
              postal_code: state.metodo_pago.codigo_postal
            }
          },
        },
      });

      if (errorCreatePayment) {
        setState({ pay: false }, 'sending');
        return message(null, errorCreatePayment?.message || 'Lo sentimos, ocurrió un error al crear el método de pago. Por favor, inténtalo de nuevo más tarde.');
      } else {
        const _comprar_suscripcion = await comprar_suscripcion({
          couponID: state?.cupon_detalles?.id,
          userID: user?.userID,
          programID: suscripcion_seleccionada?.id,
          cycleType: suscripcion_seleccionada?.plan,
          paymentMethodId: paymentMethod?.id,
          cardBrand: paymentMethod?.card?.brand,
          cardLast4: paymentMethod?.card?.last4,
          expirationMonth: paymentMethod?.card?.exp_month,
          expirationYear: paymentMethod?.card?.exp_year,
          setupIntentId: state?.setupIntentId,
        });
        if (["incomplete"].includes(_comprar_suscripcion?.status)) {
          dispatch(setSubscription({ isPaid: true }));
          const suscripcion_seleccionada_nombre = (suscripcion_seleccionada?.nombre?.replace("Fitmom", "")?.trim()).toLowerCase();
          const url_success = `${WEB_BASE_URL}/confirmacion/${suscripcion_seleccionada_nombre}-${suscripcion_seleccionada?.plan}?id=${_comprar_suscripcion?.data?.id}&method=stripe`;
          const { error: errorConfirmPayment } = await stripe.confirmPayment({
            clientSecret: _comprar_suscripcion?.subscription?.latest_invoice?.payment_intent?.client_secret,
            confirmParams: {
              return_url: url_success,
              payment_method: paymentMethod?.id
            },
            redirect: "always"
          });

          if (errorConfirmPayment) {
            dispatch(setSubscription({ isPaid: false }));
            setState({ pay: false }, 'sending');
            return message(null, errorConfirmPayment?.message || 'Lo sentimos, ocurrió un error al realizar el pago. Por favor, inténtalo de nuevo más tarde.');
          }
        } else if (["active", "trialing"].includes(_comprar_suscripcion?.status)) {
          dispatch(setSubscription({ isPaid: true }));
          const suscripcion_seleccionada_nombre = (suscripcion_seleccionada?.nombre?.replace("Fitmom", "")?.trim()).toLowerCase();
          const url_success = `/confirmacion/${suscripcion_seleccionada_nombre}-${suscripcion_seleccionada?.plan}?id=${_comprar_suscripcion?.data?.id}&method=stripe`;
          return navigate(url_success);
        }
        setState({ pay: false }, 'sending');
      }
    } catch (error) {
      dispatch(setSubscription({ isPaid: false }));
      setState({ pay: false }, 'sending');
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al realizar el pago. Por favor, inténtalo de nuevo más tarde.');
      if (parseErrorMessage(error)?.indexOf("this SetupIntent because it has been canceled") !== -1) {
        const errors = error.response.data.errors;
        if (!isEmpty(errors?.setupIntentId)) setState({ setupIntentId: errors?.setupIntentId });
      }
      if (parseErrorMessage(error)?.indexOf("cannot update this SetupIntent because it has already succeeded") !== -1) {
        getClienteSecreto(user?.userID).then(response => {
          setState({ setupIntentId: response?.data?.id });
        });
      }
    }
  }

  const createSubscriptionPaypal = useCallback(async (_data, actions) => {
    const getAmount = suscripcion_seleccionada?.plan === "mensual" ? programa_seleccionado?.cost_month : programa_seleccionado?.cost_biannual;
    const discount = state.cupon_detalles?.id ? parseFloat(calculateDiscount(getAmount, state.cupon_detalles?.discount, state.cupon_detalles?.type))?.toFixed(2) : null;
    const start_time = ["expired", "paused", "rejected", "cancelled"].includes(user.subscription?.status) ? (user.subscription?.subscriptionCancelDate || user.subscription?.nextBillingDate) : user.subscription?.nextBillingDate;

    const order = await crear_suscripcion_paypal({
      plan_id: suscripcion_seleccionada?.plan === "mensual" ? programa_seleccionado?.ProgramsDetail?.pricePaypalMonthID : programa_seleccionado?.ProgramsDetail?.pricePaypalBiannualID,
      start_time: (state?.tiene_prueba_gratis === true && isEmpty(state.cupon_detalles?.id)) ? dayjs().add(7, 'day').toDate() : (haveSubscriptionActive ? dayjs(start_time).toDate() : null),
      application_context: {
        locale: "es-MX",
      },
      plan: state.cupon_detalles?.id ? {
        billing_cycles: [
          {
            sequence: 1,
            total_cycles: 0,
            pricing_scheme: {
              fixed_price: {
                currency_code: "USD",
                value: discount <= 0 ? 0.01 : discount
              }
            }
          },
        ]
      } : null
    });
    const orderId = order?.data?.id

    return orderId;
  }, [haveSubscriptionActive, user.subscription?.status, user.subscription?.subscriptionCancelDate, user.subscription?.nextBillingDate, state.tiene_prueba_gratis, state.cupon_detalles, programa_seleccionado, suscripcion_seleccionada]);

  const onApprovePaypal = useCallback(async (_data) => {
    try {
      const _comprar_suscripcion = await comprar_suscripcion({
        couponID: state?.cupon_detalles?.id,
        userID: user?.userID,
        programID: suscripcion_seleccionada?.id,
        cycleType: suscripcion_seleccionada?.plan,
        paymentTransaction: _data?.orderID,
        subscriptionTransaction: _data?.subscriptionID,
        paypalToken: _data?.facilitatorAccessToken,
        methodPayment: "paypal",
      });

      if (_comprar_suscripcion?.status === true) {
        dispatch(setSubscription({ isPaid: true }));

        const suscripcion_seleccionada_nombre = (suscripcion_seleccionada?.nombre?.replace("Fitmom", "")?.trim()).toLowerCase();
        const url_success = `/confirmacion/${suscripcion_seleccionada_nombre}-${suscripcion_seleccionada?.plan}?id=${_comprar_suscripcion?.data?.id}&method=paypal`;
        return navigate(url_success);
      } else {
        return message('Ocurrió un problema', _comprar_suscripcion?.message || 'Lo sentimos, ocurrió un error al realizar el pago. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al realizar el pago. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [user?.userID, state?.cupon_detalles, suscripcion_seleccionada, navigate, dispatch]);

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-12 items-center gap-10'>
        <div className='md:col-span-10 flex flex-1 sm:items-center justify-center md:justify-start gap-6'>
          <div>
            <Image
              src={PagoIcon}
              imageClassName="w-11 select-none pointer-events-none mt-2 sm:mt-0"
            />
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 items-center gap-10'>
            <div className='col-span-1'>
              <h1 className='font-golos-semibold text-Crayola text-left text-3xl mb-1'>Método de pago</h1>
              <p>Selecciona un método para realizar tu pago</p>
            </div>
            <div className='col-span-1 flex items-center gap-3'>
              <button
                type="button"
                className={`${stateMetodoPago?.tabSelected === 'card' ? "font-golos-medium bg-Crayola text-white" : "text-Crayola"} border border-Crayola text-sm px-4 py-3 rounded-lg active:scale-95 transition-all`}
                onClick={() => setStateMetodoPago({ tabSelected: "card" })}
              >
                Débito / Crédito
              </button>
              <button
                type="button"
                className={`${stateMetodoPago?.tabSelected === 'paypal' ? "font-golos-medium bg-Crayola text-white" : "text-Crayola"} border border-Crayola text-sm px-5 py-3 rounded-lg active:scale-95 transition-all`}
                onClick={() => setStateMetodoPago({ tabSelected: "paypal" })}
              >
                PayPal
              </button>
            </div>
          </div>
        </div>
        <div className='col-span-1 md:col-span-2'>
          <p className='text-center md:text-left'>Paso 2 de 2</p>
        </div>
      </div>
      <div className='mt-14'>
        {stateMetodoPago.tabSelected === "card" && (
          <form className='space-y-4 animate__animated animate__fadeIn' onSubmit={handleCheckoutStripe}>
            <PaymentElement
              id="payment-element"
              options={{
                business: {
                  name: "JimeFitMom"
                },
                layout: {
                  type: 'tabs',
                },
                fields: {
                  billingDetails: {
                    name: "never",
                    address: {
                      country: 'never',
                      postalCode: "never"
                    }
                  }
                },
                paymentMethodOrder: ['card', 'apple_pay', 'google_pay', 'oxxo', 'paypal', 'blik', 'klarna', 'zip']
              }}
            />
            <div className='flex flex-col gap-4'>
              <input
                name="codigo_postal"
                type="text"
                placeholder="Código postal *"
                className="bg-white !outline-none border border-white focus-within:border-indigo-500 focus-within:ring-indigo-500 placeholder-gray-500 px-3 py-3 rounded-lg text-sm text-gray-900 w-full transition-all [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                value={state.metodo_pago.codigo_postal}
                onChange={(event) => setState({ codigo_postal: event.target.value }, "metodo_pago")}
              />
              <div className="relative rounded-lg bg-white border border-white focus-within:border-indigo-500 focus-within:ring-indigo-500 flex items-center transition-all">
                <input
                  name="cupon"
                  type="text"
                  placeholder="Cupón"
                  className="autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] !border-0 !outline-none !ring-0 placeholder-gray-500 px-3 py-3 rounded-lg text-sm text-gray-900 uppercase w-full transition-all"
                  value={state.metodo_pago.cupon}
                  onChange={(event) => setState({ cupon: event.target.value }, "metodo_pago")}
                />
                <div className="flex flex-row gap-1 justify-center">
                  {state.sending.cupon ? (
                    <ProgressSpinner style={{ width: '1rem', height: '1rem', margin: 0, marginRight: 10 }} strokeWidth="3" animationDuration=".5s" />
                  ) : (
                    <>
                      {state.cupon_detalles?.id && (
                        <button type="button" className='py-3 px-2 text-Crayola text-sm underline active:scale-90 transition-all' onClick={() => setState((prevState) => ({ ...prevState, cupon_detalles: null, metodo_pago: { ...prevState.metodo_pago, cupon: "" } }))}>Eliminar</button>
                      )}
                      <button type="button" className='py-3 pl-2 pr-3 text-Crayola text-sm underline active:scale-90 transition-all' onClick={() => onSearchCoupon(state.metodo_pago.cupon)}>Aplicar</button>
                    </>
                  )}
                </div>
              </div>
            </div>
            {state.cupon_detalles?.id && (
              <div className='flex justify-end'>
                <p className='font-golos text-sm'>
                  <span className='mr-1'>Descuento del cupón:</span>
                  <span className='text-red-600'>{state.cupon_detalles?.type === 'porcentaje' ? `${state.cupon_detalles?.discount}%` : `$${parseFloat(state.cupon_detalles?.discount)?.toFixed(2)}`}</span>
                </p>
              </div>
            )}
            <div className='flex justify-center pt-8'>
              <ButtonPrimary
                text={state.cupon_detalles?.id ? "¡Comprar suscripción!" : "¡Inicia tu prueba gratis!"}
                withIcon
                classNameButton={"py-4 px-6"}
                type="button"
                loading={state.sending?.pay}
                loadingText={"Procesando"}
                onClick={handleCheckoutStripe}
              />
            </div>
            <div>
              <p className='text-center text-sm mt-10'>*Recuerda que puedes cancelar tu programa en cualquier momento.</p>
            </div>
          </form>
        )}
        {stateMetodoPago.tabSelected === "paypal" && (
          <div className='flex flex-col items-center justify-center gap-6 animate__animated animate__fadeIn'>
            <div className='inline-flex flex-col gap-2'>
              {(isPending) ? <Loading /> : (
                <>
                  <PayPalButtons
                    style={{
                      shape: 'rect',
                      layout: 'vertical', //default value. Can be changed to horizontal
                    }}
                    createSubscription={createSubscriptionPaypal}
                    onApprove={onApprovePaypal}
                    onCancel={(data, actions) => actions.redirect()}
                    onError={() => { }}
                    forceReRender={[user, state?.cupon_detalles]}
                  />
                  <p className='text-xs text-gray-500'>Elige PayPal para mayor comodidad y seguridad. Al hacerlo, permites que JimeFitMom realice futuros cargos en tu tarjeta según lo acordado.</p>
                </>
              )}
            </div>

            <div className='flex flex-col gap-2 w-full'>
              <div className="relative rounded-lg bg-white border border-white focus-within:border-indigo-500 focus-within:ring-indigo-500 flex items-center transition-all">
                <input
                  name="cupon"
                  type="text"
                  placeholder="Cupón"
                  className="autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] !border-0 !outline-none !ring-0 placeholder-gray-500 px-3 py-3 rounded-lg text-sm text-gray-900 uppercase w-full transition-all"
                  value={state.metodo_pago.cupon}
                  onChange={(event) => setState({ cupon: event.target.value }, "metodo_pago")}
                />
                <div className="flex flex-row gap-1 justify-center">
                  {state.sending.cupon ? (
                    <ProgressSpinner style={{ width: '1rem', height: '1rem', margin: 0, marginRight: 10 }} strokeWidth="3" animationDuration=".5s" />
                  ) : (
                    <>
                      {state.cupon_detalles?.id && (
                        <button type="button" className='py-3 px-2 text-Crayola text-sm underline active:scale-90 transition-all' onClick={() => setState((prevState) => ({ ...prevState, cupon_detalles: null, metodo_pago: { ...prevState.metodo_pago, cupon: "" } }))}>Eliminar</button>
                      )}
                      <button type="button" className='py-3 pl-2 pr-3 text-Crayola text-sm underline active:scale-90 transition-all' onClick={() => onSearchCoupon(state.metodo_pago.cupon)}>Aplicar</button>
                    </>
                  )}
                </div>
              </div>
              {state.cupon_detalles?.id && (
                <div className='flex justify-end w-full'>
                  <p className='font-golos text-sm text-right'>
                    <span className='mr-1'>Descuento del cupón:</span>
                    <span className='text-red-600'>{state.cupon_detalles?.type === 'porcentaje' ? `${state.cupon_detalles?.discount}%` : `$${parseFloat(state.cupon_detalles?.discount)?.toFixed(2)}`}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
});

const App = (props) => {
  const [apiKeys, setApiKeys] = useCustomState(null);

  useEffect(() => {
    getApiKeys().then(async apiKeys => {
      setApiKeys(apiKeys);
    });
    // eslint-disable-next-line
  }, []);

  if (!apiKeys) return <Loading containerClassName={"flex-1 h-full py-10"} />;

  return (
    <PayPalScriptProvider
      options={{
        clientId: apiKeys.paypal,
        intent: "subscription",
        disableFunding: "card,credit,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo",
        vault: true,
        locale: "es_MX"
      }}
    >
      <Carrito apiKeysRemote={apiKeys} {...props} />
    </PayPalScriptProvider>
  );
};

export default App;